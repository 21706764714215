import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SecuredIdentificationActive } from '@resursbank/ya-react-lib';
import content from './content.json';

const mapStateToProps = state => {
    return {
        env: state.info.env,
    };
};

function Identification({ env }) {
    const data = React.useMemo(() => {
        const isProd = env === 'prod';

        return {
            keycloakData: {
                url: isProd
                    ? 'https://keycloak.yabank.c.bitbit.net'
                    : 'https://keycloak-test.yabank.c.bitbit.net',
                realm: 'kunder',
                clientId: 'forside_implicit',
            },
            identificationActiveData: {
                content,
                fullTermsAndConditionsLink:
                    'https://documenthandler.resurs.com/Dokument.pdf?customerType=NATURAL&docType=Fullstendige-vilkar-ya-betalingsforsikring-for-lan&land=NO&language=no',
                insuranceTermsLink:
                    'https://documenthandler.resurs.com/Dokument.pdf?customerType=NATURAL&docType=Fullstendige-vilkar-ya-betalingsforsikring-for-lan&land=NO&language=no',
                termsOfPurchaseLink:
                    'https://documenthandler.resurs.com/Dokument.pdf?customerType=NATURAL&docType=Forkjopsinformasjon-ya-betalingsforsikring-for-lan&land=NO&language=no',
                cmsDomain: isProd
                    ? 'https://cmshelper-cust-yabank-prod.apps.okd.paas.h.bitbit.net'
                    : 'https://cmshelper-cust-yabank-stage.apps.okd.paas.h.bitbit.net',
                sblDomain: isProd
                    ? 'https://sblbin-cust-yabank-prod.apps.okd.paas.h.bitbit.net'
                    : 'https://sblbin-cust-yabank-stage.apps.okd.paas.h.bitbit.net',
            },
        };
    }, [env]);
    return <SecuredIdentificationActive {...data} />;
}

Identification.propTypes = {
    env: PropTypes.string,
};

export default connect(mapStateToProps)(Identification);
